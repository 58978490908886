<template>
  <div></div>
</template>

<script>
  export default {
    name: 'ItemSummaryPage'
  };
</script>

<style scoped>

</style>